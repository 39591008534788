/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LinkWithQuery, MapInfo, LandingPageCta, QuestionAccordion, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!QuestionAccordion) _missingMdxReference("QuestionAccordion", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/prices/'], ['en', 'https://www.hear.com/hearing-aids/prices/'], ['en-US', 'https://www.hear.com/hearing-aids/prices/'], ['en-IN', 'https://www.hear.com/in/hearing-aids/prices'], ['en-CA', 'https://ca.hear.com/hearing-aids/prices/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The cost of medical-grade ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/"
  }, "hearing aids"), " depends on the level of ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/technology"
  }, "hearing aid technology"), " needed to support your lifestyle. The more active you are, the more technology you require. Our wide selection allows us to offer hearing aid prices starting at $499 a pair. Most of our customers select ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "medical grade hearing aid"), " cost ranging from $139 to $199 per month over 36 months. Hearing aids last 5 years on average, so over their lifetime, this comes out to $2.30 a day. When compared to other day-to-day expenses, such as cars, entertainment, or the gym, it’s a small investment for a much higher quality of life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A 2001 study by R. Adler found that we spend 70% of our time engaged in some form of communication. Of that time, we spend about 9% writing, 16% reading, 30% speaking, and ", React.createElement(_components.strong, null, "45% listening"), ". That’s over 5 hours a day! So invest accordingly because hearing aids will be the most used device you own."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we specialize in matching you with the best hearing aids to meet your specific needs, lifestyle, and budget. We partner with all the leading manufacturers and the best local hearing care professionals to ensure you are 100% satisfied or your money back."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Every hearing aid manufacturer offers several different styles (the outside) and levels of hearing aid technology (the inside). Pricing is determined by the technology (inside) and not the style (outside). Our most popular hearing aids can be classified into the technology levels and price points below."), "\n", React.createElement("img", {
    className: "o-fluid-img u-mobile-hidden",
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hear.com-pricing.jpg",
    alt: "Prices",
    loading: "lazy"
  }), "\n", React.createElement("img", {
    className: "o-fluid-img u-desktop-hidden",
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-premium.jpg",
    alt: "Price premium",
    style: {
      paddingBottom: '15px'
    },
    loading: "lazy"
  }), "\n", React.createElement("img", {
    className: "o-fluid-img u-desktop-hidden",
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-advanced.jpg",
    alt: "Price advanced",
    style: {
      paddingBottom: '15px'
    },
    loading: "lazy"
  }), "\n", React.createElement("img", {
    className: "o-fluid-img u-desktop-hidden",
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/price-essentials.jpg",
    alt: "Price essentials",
    loading: "lazy"
  }), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we provide our customers with a comprehensive, all-inclusive package. There are no hidden costs or additional charges, so you know exactly what to expect. In addition to the latest, medical-grade hearing aids, our prices include ", React.createElement(_components.strong, null, "over $2000 of additional value"), ":"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "licensed-hearing-care-services-that-make-a-difference",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#licensed-hearing-care-services-that-make-a-difference",
    "aria-label": "licensed hearing care services that make a difference permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Licensed hearing care services that make a difference"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best hearing aids are only as good as the professional that programs them. Services include the hearing test, programming/fitting of the hearing aids, and as many additional appointments needed to fine-tune your hearing aids. This will ensure you’re happy with your hearing experience. The market value for these services can add up to $500-$1000 but are included in our prices."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "aftercare-services-to-extend-the-life-of-the-device-and-maximize-effectiveness",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#aftercare-services-to-extend-the-life-of-the-device-and-maximize-effectiveness",
    "aria-label": "aftercare services to extend the life of the device and maximize effectiveness permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Aftercare services to extend the life of the device and maximize effectiveness"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We include 5 additional follow-up visits for fine-tuning or annual check-ups after the end of the trial period. Hearing ability can change over time, so this ensures the settings are always suitable for you. These appointments include professional cleaning, which improves performance and extends the lifetime of the devices. The cost of the 5 appointments alone is worth $250-$500."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "manufacturers-warranty-against-loss-and-damages-just-in-case",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manufacturers-warranty-against-loss-and-damages-just-in-case",
    "aria-label": "manufacturers warranty against loss and damages just in case permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manufacturer’s warranty against loss and damages (just in case)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Medical-grade hearing aids are extremely durable, but if you wear them all day, every day (like you should) accidents can happen, so you want to be covered. Most devices come with a 3-year warranty on loss and damages, but a few of the lower-cost devices only have 1 year. Similar 3-year warranties can cost over $1000 (ex: AppleCare+ is $299 for two years on a $1000 phone)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-aid-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-aid-specialist-from-hearcom",
    "aria-label": "contact a hearing aid specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing aid specialist from Hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now that you have all this information, our recommendation to everyone is to get as much technology as you can afford. From all our interactions with customers, advanced programs and added conveniences will greatly increase your chances of success. Speech will be clearer, the sound will be more natural, and your life will be easier – even in more challenging environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best technology really does make a difference. If you haven’t already signed up for our 45-day trial, click the button below to experience it for yourself."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "frequently-asked-questions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#frequently-asked-questions",
    "aria-label": "frequently asked questions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Frequently asked questions"), "\n", React.createElement(QuestionAccordion, {
    question: "Are cheap hearing aids good?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The effectiveness of cheap hearing aids can vary, and it often depends on several factors, including the specific brand, model, and the individual's hearing needs. Some lower-cost hearing aids may offer basic amplification and address mild to moderate hearing loss adequately. However, they may lack advanced features found in more expensive models, such as noise cancellation, speech enhancement, own voice processing, Bluetooth connectivity, and customization options."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about ", React.createElement(_components.a, {
    href: "/resources/hearing-aids/guide-to-price-value/",
    className: "c-md-a"
  }, "the price and value of hearing aids"), ".")), "\n", React.createElement(QuestionAccordion, {
    question: "Do you offer discount for U.S. Veterans?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "At the moment, the VA provides free hearing aids for U.S. veterans. To be eligible for this benefit, you must meet certain requirements. The best way to find out if you have any applicable VA benefits is to contact your nearest VA clinic. Below you will find general information about hearing loss and military veterans, VA benefits, eligibility, and our special hear.com offer for former military personnel."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we provide comprehensive support and guidance. As a thank you for your service, we also give you a special veteran’s discount. In some cases, we can apply a 15% discount on whichever one of our brand-name hearing aids you would like."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about ", React.createElement(_components.a, {
    href: "/hearing-aids/veterans/",
    className: "c-md-a"
  }, "hearing aids for veterans"), ".")), "\n", React.createElement(QuestionAccordion, {
    question: "Does hear.com offer financial assistance?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your insurance does not offer enough coverage for hearing aids, your next step is to consider financing, which allows you to distribute the cost of your devices evenly in convenient monthly payments. We offer financing from Allegro and CareCredit."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Anyone can apply for our flexible financing, which is subject to credit approval by Synchrony Bank. We want to make sure that price doesn’t get in the way of improving your quality of life. Our hearing aid experts can answer any questions related to health insurance and financing. Don’t hesitate to contact us at any time to help finance your hearing aids."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn more about ", React.createElement(_components.a, {
    href: "/hearing-aids/financial-assistance/",
    className: "c-md-a"
  }, "hearing aid financial assistance"), ".")), "\n", React.createElement(QuestionAccordion, {
    question: "Is there warranty or guaranty?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Yes, hear.com offers a 45-day trial with money-back guarantee. However, it's crucial to provide yourself with a fair opportunity to adapt to your hearing aid, recognizing that it frequently takes a few months to feel fully comfortable and adjusted.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Aids",
    ContentCopy2: "Connect you to the world around you with our wide range of devices.",
    Url2: "/hearing-aids/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Loss",
    ContentCopy3: "All you need to know about Hearing Loss.",
    Url3: "/hearing-loss/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
